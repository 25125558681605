import React from 'react';
import { Segment } from 'semantic-ui-react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Cart = () => (
  <Layout>
    <SEO
      title="Cart"
      keywords={['duck', 'hunt', 'Georgia', 'Hartwell', 'checkout']}
    />
    <Segment placeholder style={{ padding: '4em 0em' }} vertical />
  </Layout>
);

export default Cart;
